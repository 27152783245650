// site original modules
import { HomeScript } from './modules/_homeScript';
import { ProductDetailScript } from './modules/_productDetailScript.js';

(function(window, document, $) {
  $(function() {
      const sppec = {};

      /**
       * ヘッダーカート開閉
       */
      sppec.headerCartNavi = () => {
        const $siteHeader__cartNav = $('#js-siteHeader__cartNav');
        const toggleButtonId = '#js-headerCartNavi__toggle';
        const cancelButtonId = '#js-headerCartContent__cancel';

        $siteHeader__cartNav.on('click', toggleButtonId, () => {
          $('#js-headerCartContent').toggleClass('is-active');
        });
        $siteHeader__cartNav.on('click', cancelButtonId, () => {
          $('#js-headerCartContent').toggleClass('is-active');
        });
      };

      /**
       * モバイルメニュー開閉
       */
      sppec.drawerMenu = () => {
        const activeClassName = 'is-active';
        const $body = $('body');
        const $overlay = $('#js-overlay');
        const $drawerMenu = $('#js-drawerMenu');
        const $drawerMenuButton = $('#js-drawerMenuButton');
        const $drawerMenuClose = $('#js-drawerMenuClose');

        $drawerMenuButton.on('click', function() {
          // $('.ec-layoutRole').toggleClass('is_active');
          $drawerMenu.toggleClass(activeClassName);
          $drawerMenuClose.toggleClass(activeClassName);
          $body.toggleClass('have_curtain');
        });

        $overlay.on('click', function() {
          // $('.ec-layoutRole').removeClass('is_active');
          $drawerMenu.removeClass(activeClassName);
          $drawerMenuClose.removeClass(activeClassName);
          $body.removeClass('have_curtain');
        });

        $drawerMenuClose.on('click', function() {
            // $('.ec-layoutRole').removeClass('is_active');
            $drawerMenu.removeClass(activeClassName);
            $drawerMenuClose.removeClass(activeClassName);
            $body.removeClass('have_curtain');
        });
      };

      /**
       * 汎用アコーディオンパネル
       */
      sppec.accordionNext = () => {
        const $accordionNext = $('.js-accordionNext');

        if ($accordionNext.length < 1) {
          return;
        }

        // 初期表示処理
        $accordionNext.each((index, element) => {
          if (
            $(element).parent().attr('aria-expanded') === 'false'
          ) {
            $(element).next().attr('aria-hidden', 'true').hide();
          }
        });

        const toggleAccordion = (event) => {
          const $_this = $(event.currentTarget);
          const $next = $_this.next();
          const $parent = $_this.parent();

          if ( $parent.attr('aria-expanded') === 'true' ) {
            $parent.attr('aria-expanded', 'false');
            $next.attr('aria-hidden', 'true');
            $next.slideUp(200);
          } else {
            $parent.attr('aria-expanded', 'true');
            $next.attr('aria-hidden', 'false');
            $next.slideDown(200);
          }
        };

        // クリック時処理
        $accordionNext.on('click', (event) => {
          toggleAccordion(event);
        });
        // keyup処理(enter, space)
        $accordionNext.on('keyup', (event) => {
          if (event.which == 13 || event.which == 32) {
            toggleAccordion(event);
          }
        });
      };

      /**
       * 汎用アコーディオンパネル
       */
      sppec.scrollTo = () => {
        const scrollButton = document.querySelectorAll('.js-scrollTo');
        Array.prototype.forEach.call(scrollButton, function(element){
          element.addEventListener('click', (event) => {

            if(/#/.test(element.getAttribute('href'))){
              event.preventDefault();

              let href = element.getAttribute('href');
              let target;

              if (href === '#') {
                target = document.getElementById('page-top');
              } else {
                target = document.querySelector(href);
              }

              target.scrollIntoView({
                behavior: 'smooth',
                // block: 'nearest',
                inline: 'nearest'
              });
            }
          });
        });
      };

      sppec.scrollTo();
      sppec.headerCartNavi();
      sppec.drawerMenu();
      sppec.accordionNext();
      HomeScript();
      ProductDetailScript();
    }
  );
})(window, document, jQuery);
