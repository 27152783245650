export const HomeScript = () => {

  if ( document.querySelector('#page_homepage') === null ) {
    return false;
  }

  $('#js-homeHeroSlider').slick({
    infinite: false,
    // dots: true,
    arrows: false,
    autoplay: true,
    speed: 300
  });

  const homeProductsSlider = () => {
    const mediaQueryList = window.matchMedia('(max-width:907px)');
    const $recommendSlider = $('.js-recommendSlider');
    const slickOption = {
      responsive:[
        {
          breakpoint: 10000,
          settings: 'unslick'
        },
        {
          breakpoint: 907,
          settings: {
            variableWidth: true,
            infinite: false,
            arrows: true,
            centerMode: true,
          }
        }
      ]
    };
    const listener = (event) => {
      if (event.matches) {
        $recommendSlider.slick('slickSetOption', slickOption, true);
      } else {
        $recommendSlider.slick('unslick');
      }
    };

    //初回Slick起動
    $recommendSlider.slick(slickOption);

    //ブレークポイントで切り替え
    mediaQueryList.addEventListener('change', listener, false);
  };

  homeProductsSlider();
};