export const ProductDetailScript = () => {

  if ( document.querySelector('#page_product_detail') === null ) {
    return false;
  }

  const itemSlider = () => {

    /**
     * 商品詳細スライダー
     */
    $('.js-itemSlider__visual').slick({
      arrows: false,
      infinite: false,
      asNavFor: '.js-itemSlider__nav'
    });
    $('.js-itemSlider__nav').slick({
      slidesToShow: 3.5,
      infinite: false,
      asNavFor: '.js-itemSlider__visual',
      focusOnSelect: true
    });
  };

  itemSlider();
};